<template>
      <!-- Section -->
      <section class="wrapper style2">
        <div class="inner">
          <div class="flex flex-2">
            <div class="col col2">
              <h3 style="font-size: 45px;">车载终端</h3>
              <p style="font-size: 18px;">
                我们的车载终端基于先进的车辆管理系统，能够实现车辆的实时监控和管理。
              </p>
              <p style="font-size: 18px;">
                具有高可靠性、稳定性、耐久性等特点，可广泛应用于物流、运输、公共交通等领域。
              </p>
              <!-- <a href="#" class="button">了解更多</a> -->
            </div>
            <div class="col col1 first">
              <div class="image round fit">
                <!-- <a href="generic.html" class="link"> -->
                  <img
                    src="@\assets\images\solution\car.png"
                    alt=""
                    width="320"
                    height="320"
                  />
                <!-- </a> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Section -->
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
// import "@/views/solution/main.css"
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
}
    return { productadvList: result, target }
  }
}
</script>
<style src="@/views/solution/main.css" scoped>

</style>
