<template>
      <!-- Section -->
      <section class="wrapper style2">
        <div class="inner">
          <div class="flex flex-2">
            <div class="col col2">
              <h3 style="font-size: 45px;margin-bottom: 45px;">数据采集网关</h3>
              <div style="vertical-align: middle;">
                <p style="font-size: 18px;">
                  我们的数据采集网关能够实现各种设备的连接和数据采集，支持多种通信协议和数据格式，具有强大的数据处理和传输能力。
                </p>
                <p style="font-size: 18px;">
                  可广泛应用于智能家居、智慧城市、工业物联网等领域。
                </p>
              </div>
              <!-- <a href="#" class="button">了解更多</a> -->
            </div>
            <div class="col col1 first">
              <div class="image round fit">
                <!-- <a href="generic.html" class="link"> -->
                  <img
                    src="@\assets\images\solution\gateway.png"
                    alt=""
                    width="320"
                    height="320"
                  />
                <!-- </a> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Section -->
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
// import "@/views/solution/main.css"
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
}
    return { productadvList: result, target }
  }
}
</script>
<style src="@/views/solution/main.css" scoped>

</style>
