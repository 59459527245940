<template>
    <section class="wrapper style1">
        <div class="inner">
          <!-- 2 Columns -->
          <div class="flex flex-2">
            <div class="col col1">
              <div class="image round fit">
                  <img
                    src="@\assets\images\solution\sensor.png"
                    alt=""
                    width="320"
                    height="320"/>
              </div>
            </div>
            <div class="col col2">
              <h3 style="font-size: 45px;margin-bottom: 45px;">传感器</h3>
              <div style="vertical-align: middle;">
                <p class="describtion" style="font-size: 18px;">
                  我们提供集成了先进传感器的解决方案，用于实时监测和测量各种物理量，如温度、湿度、压力、光照等。
                </p>
                <p  class="describtion" style="font-size: 18px;">
                  我们的传感器具有高精度、可靠性、稳定性等特点，可广泛应用于工业自动化、环境监测、农业物联网等领域。
                </p>
              </div>
              <!-- <RouterLink  to="/solutions" class="button">了解更多</RouterLink> -->
              <!-- <a href="/src/views/solutions/index.vue" class="button">了解更多</a> -->
            </div>
          </div>
        </div>
      </section>
</template>

<script>
    import { ref } from 'vue'
    import { findBrand } from '@/api/home'
    import { useLazyData } from '@/hooks'

    export default {
      name: 'HomeBrand',
      components: {  },
      setup () {
         const { target, result } = {
        "target": "操作成功",
    }
        return { productadvList: result, target }
      }
    }
</script>
<style src="@/views/solution/main.css" scoped>
  .col.col2{
    display: flex;
    flex-wrap:wrap ; 
    align-content:center ;
     vertical-align: center;
    
  }
</style>