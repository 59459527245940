<template>
    <section class="wrapper style1">
        <div class="inner">
          <!-- 2 Columns -->
          <div class="flex flex-2">
            <div class="col col1">
              <div class="image round fit">
                  <img
                    src="@\assets\images\solution\computer.png"
                    alt=""
                    width="320"
                    height="320"/>
              </div>
            </div>
            <div class="col col2">
              <h3 style="font-size: 45px;">工控电脑</h3>
              <p style="font-size: 18px;">
                我们的工控电脑基于高性能处理器和硬件加速技术，具有强大的数据处理和控制能力，能够满足各种工业自动化应用的需求。
              </p>
              <p style="font-size: 18px;">
                具有高可靠性、稳定性、安全性等特点，可广泛应用于制造业、能源、交通运输等领域。
              </p>
              <!-- <a href="#" class="button">了解更多</a> -->
            </div>
          </div>
        </div>
      </section>
</template>

<script>
    import { ref } from 'vue'
    import { findBrand } from '@/api/home'
    import { useLazyData } from '@/hooks'

    export default {
      name: 'HomeBrand',
      components: {  },
      setup () {
         const { target, result } = {
        "target": "操作成功",
    }
        return { productadvList: result, target }
      }
    }
</script>
<style src="@/views/solution/main.css" scoped>

</style>