<template>
    <section class="wrapper style1">
        <div class="inner">
          <!-- 2 Columns -->
          <div class="flex flex-2">
            <div class="col col1">
              <div class="image round fit">
                  <img
                    src="@\assets\images\IOT.png"
                    alt=""
                    width="320"
                    height="320"/>
              </div>
            </div>
            <div class="col col2">
              <h3 style="font-size: 45px;">物联网教具</h3>
              <p style="font-size: 18px;">
                我们的物联网教具能够帮助学生和教师了解和掌握物联网技术的原理和应用。
              </p>
              <p style="font-size: 18px;">
                我们的教具具有直观、易用、互动性强的特点，可广泛应用于中小学、大学、培训机构等教育机构。
              </p>
              <!-- <a href="#" class="button">了解更多</a> -->
            </div>
          </div>
        </div>
      </section>
</template>

<script>
    import { ref } from 'vue'
    import { findBrand } from '@/api/home'
    import { useLazyData } from '@/hooks'

    export default {
      name: 'HomeBrand',
      components: {  },
      setup () {
         const { target, result } = {
        "target": "操作成功",
    }
        return { productadvList: result, target }
      }
    }
</script>
<style src="@/views/solution/main.css" scoped>

</style>