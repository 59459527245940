<template>
  <!-- <link rel="stylesheet" href="./main.css"> -->
  
    <!-- 面包屑 -->
    <!-- <BaseBread>
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>行业解决方案</BaseBreadItem>
    </BaseBread> -->
     <div class="container">
    <img src="@\assets\images\solution\solutionBanner.png" style="width: 100%;" alt="" />
    <!--文本分界线 begin-->
    <!-- 模板分界线 -->
   
    <div id="section1">
      <AdvStyle1></AdvStyle1>
    </div>
    <div>  
      <AdvStyle2></AdvStyle2> 
    </div>
    <div></div>
      <AdvStyle3></AdvStyle3>
    
      <AdvStyle4></AdvStyle4>
    <div id="section5">
      <AdvStyle5></AdvStyle5>
    </div>  
    <!-- 模板分界线 -->
    <!-- <div class="text-split-line">
      <div class="info">
        <p>精选组合搭配，更实惠，更省心</p>
        <p>
          提供1对1响应式定制建站服务，域名+建站组合特惠低至388元，买即享1元换购400电话
          ，咨询客服领优惠
        </p>
      </div>
    </div> -->
    <!-- <AdvInfo></AdvInfo> -->
    <!--文本分界线 begin-->
    <!-- <div class="text-split-line">
      <div class="info">
        <p>60+行业覆盖，3000+精品模板，随心DIY</p>
        <p>不用懂代码，选择模板，PPT式拖拽编辑，快速搭建网站</p>
      </div>
    </div> -->
    <!-- <AdvList></AdvList>
    <AdvVideo></AdvVideo> -->
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import useStore from "@/store";
import { findAd } from "@/api/home";
import { ref } from "vue";
import AdvInfo from "./components/adv-info.vue";
import AdvList from "./components/adv-list.vue";
import AdvVideo from "./components/adv-video.vue";
import AdvStyle1 from "./components/adv-style1.vue";
import AdvStyle2 from "./components/adv-style2.vue";
import AdvStyle3 from "./components/adv-style3.vue";
import AdvStyle4 from "./components/adv-style4.vue";
import AdvStyle5 from "./components/adv-style5.vue";



// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: "HomeBanner",
  components: { AdvInfo, AdvList, AdvVideo,AdvStyle1,AdvStyle2,AdvStyle3,AdvStyle4,AdvStyle5},
  setup() {
    const dataSource = ref([]);
    const dataSource2 = ref([]);
    findAd("techplatImage").then((data) => {
      dataSource.value = data.data.list[0];
    });
    findAd("techplatImage2").then((data) => {
      dataSource2.value = data.data.list[0];
    });
    return { dataSource, dataSource2 };
  },
};
</script>

<style scoped lang="less">
.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;
  .info {
    padding-left: 10px;
    flex: 1;
    p {
      &:first-child {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 18px;
      }
      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}
//添加css样式
</style>
